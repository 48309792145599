import {
  PAGES_PER_ARCHIVE_PAGE,
  POSTS_PER_ARCHIVE_PAGE,
  VISIBLE_PAGES_PER_ARCHIVE_PAGE,
} from "@/config/constants";

// Generates an array of page numbers to be used with the
// pagination component on archive pages
export function getPageNumbers(currentPage, totalPages) {
  const pages = [];
  const pagesRemaining = totalPages - currentPage;
  let previousPagesStartIndex =
    pagesRemaining > 2
      ? currentPage - 2
      : currentPage - (2 + 2 - pagesRemaining);
  while (previousPagesStartIndex < currentPage) {
    if (previousPagesStartIndex > 0) {
      pages.push(previousPagesStartIndex);
    }
    previousPagesStartIndex += 1;
  }

  pages.push(currentPage);

  let nextPagesStartIndex = currentPage + 1;
  while (
    nextPagesStartIndex <= totalPages &&
    pages.length < VISIBLE_PAGES_PER_ARCHIVE_PAGE
  ) {
    pages.push(nextPagesStartIndex);
    nextPagesStartIndex += 1;
  }

  return pages;
}

// Determine total pages of archive content
export function getTotatPostsPages(posts) {
  if (!posts || !posts.pageInfo || !posts.pageInfo.offsetPagination) {
    return 0;
  }
  return (
    Math.ceil(posts.pageInfo.offsetPagination.total / POSTS_PER_ARCHIVE_PAGE) -
    1
  );
}

export function getTotalPagePages(pages) {
  if (!pages || !pages.pageInfo || !pages.pageInfo.offsetPagination) {
    return 0;
  }
  return (
    Math.ceil(pages.pageInfo.offsetPagination.total / PAGES_PER_ARCHIVE_PAGE) -
    1
  );
}
