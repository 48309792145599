import { transformHeadTagURLs } from "./seo";
import { unescapeHTML } from "./word";

// Format WP category content / data for archive page
export function formatTagsForArchivePage(data) {
  const edges = data?.tags?.edges || [];
  return edges.reduce((categoryMap, edge) => {
    const node = edge?.node;
    if (!node) return categoryMap;

    const slug = node?.slug;
    if (!slug) return categoryMap;

    return {
      ...categoryMap,
      [slug]: {
        ...node,
        seo: {
          title: node?.seo?.title || unescapeHTML(node?.name),
          metaDesc: node?.seo?.metaDesc,
          fullHead: transformHeadTagURLs(
            node?.seo?.fullHead,
            node?.seo?.canonical
          ),
        },
      },
    };
  }, {});
}

export const getTagsFromSportsMenu = (menuOptions) => {
  const tags = [];

  for (const category in menuOptions) {
    if (menuOptions.hasOwnProperty(category)) {
      const slug = menuOptions[category]?.slug;
      if (slug) {
        tags.push(slug);
      }
    }
  }

  return tags;
};
