import { BN_URL } from "@/config/constants";

import { formatOgImageWithFullHead, formatOpenGraphImage } from "./seo";

// Format WP author content / data
export function formatAuthorDetails(author) {
  if (!author) return null;
  const canonical = `${BN_URL}${author?.uri ?? "/author/"}`;
  const formattedAuthor = {
    ...author,
    avatar: author?.avatar ? author?.avatar?.url : null,
    id: author?.id ?? null,
    seo: author?.seo
      ? {
          title: author?.seo?.title || "",
          metaDesc: author?.seo.metaDesc || null,
          canonical: canonical,
          ogType: author?.seo?.opengraphType ?? null,
          ogImage: author?.seo?.opengraphImage
            ? formatOpenGraphImage(author?.seo?.opengraphImage)
            : formatOgImageWithFullHead(author?.seo?.fullHead) ?? null,
          schema: author?.seo?.schema?.raw ?? null,
        }
      : null,
    twitter:
      author?.seo && author?.seo.social ? author?.seo.social?.twitter : null,
  };

  return formattedAuthor;
}
