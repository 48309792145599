import { BN_URL, POSTS_PER_ARCHIVE_PAGE } from "@/config/constants";
import { formatAffiliates } from "@/utilities/affiliates";
import { formatAuthorDetails } from "@/utilities/author";
import { formatBanners } from "@/utilities/banners";
import {
  formatAllCategoryPostsSlug,
  formatCategories,
  formatCategoriesForArchivePage,
} from "@/utilities/categories";
import { fetchAPICommon } from "@/utilities/fetchAPI";
import { formatPage, formatPreloadPagesPaths } from "@/utilities/pages";
import { formatPagesWithTags } from "@/utilities/pagesTags";
import { getTotalPagePages, getTotatPostsPages } from "@/utilities/pagination";
import {
  formatPagesForList,
  formatPost,
  formatPostForMatchup,
  formatPostsByTagForList,
  formatPostsForList,
} from "@/utilities/posts";
import { formatCTASportbooks404Shortcode } from "@/utilities/shortcodes";
import { formatShows } from "@/utilities/shows";
import { formatTagsForArchivePage } from "@/utilities/tags";
import { formatTopMenu } from "@/utilities/topmenu";

async function fetchAPI(path, { params } = {}) {
  const headers = {
    "Content-Type": "application/json",
    "x-webhook-secret": process.env.WEBHOOK_SECRET,
  };
  const queryParams = new URLSearchParams(params);
  return await fetchAPICommon(
    `${process.env.NEXT_PUBLIC_BETTING_NEWS_API_GRAPHQL_URL}/wp-graphql/${path}${queryParams ? "?" + queryParams : ""}`,
    {
      method: "GET",
      headers,
    }
  );
}

export async function getAffiliates() {
  const data = await fetchAPI("affiliates");
  return formatAffiliates(data);
}

export async function getCTASportbooks404Shortcode() {
  const data = await fetchAPI("cta-sportbooks-404-shortcode");
  return formatCTASportbooks404Shortcode(data);
}

export async function getPagesToPreloadPaths() {
  const data = await fetchAPI("pages-to-preload-paths");
  return formatPreloadPagesPaths(data);
}

export async function getAuthorBySlug(author) {
  const data = await fetchAPI("author-by-slug", {
    params: { author },
  });
  return formatAuthorDetails(data?.user);
}

export async function getAuthorsSlug() {
  const data = await fetchAPI("authors-slug");
  return data?.users?.edges.map((author) => author.node.slug) || [];
}

export async function getCategoriesForArchivePage() {
  const data = await fetchAPI("categories");
  return formatCategoriesForArchivePage(data);
}

export async function getCategoryUris() {
  const data = await fetchAPI("category-uris");
  return formatCategories(data);
}

export async function getPageContentByURI(path) {
  const data = await fetchAPI("page-by-content-uri", {
    params: { path },
  });
  if (data?.page) {
    if (!data.page.seo) {
      data.page.seo = {};
    }
    data.page.seo.canonical = `${BN_URL}${path}`;
  }
  return formatPage(data);
}

export async function getPostArchives({ type, value, pageNumber }) {
  const POSTS_PER_REQUEST = 10;
  const offset = pageNumber * POSTS_PER_ARCHIVE_PAGE;

  const types = {
    author: "authorName",
    category: "categoryName",
    search: "search",
  };

  const { archives, totalPages } = await fetchAndFormatArchivePosts({
    value,
    type: types[type] || types["category"],
    offset,
    perPage: POSTS_PER_REQUEST,
  });

  return { archives, totalPages };
}

async function fetchAndFormatArchivePosts({ type, value, offset, perPage }) {
  const data = await fetchAPI("archive-posts", {
    params: { type, value, offset, perPage },
  });

  const archives = formatPostsForList(data);
  return { archives, totalPages: getTotatPostsPages(data?.posts) };
}

export async function getPageArchives({ type, value }) {
  const PAGES_PER_REQUEST = 10;

  const types = {
    author: "authorName",
    category: "categoryName",
    search: "search",
  };

  const { pageArchives, totalPagesArchive } = await fetchAndFormatArchivePages({
    value,
    type: types[type] || types["category"],
    perPage: PAGES_PER_REQUEST,
  });

  return { pageArchives, totalPagesArchive };
}

async function fetchAndFormatArchivePages({ type, value, perPage }) {
  const data = await fetchAPI("archive-pages", {
    params: { type, value, perPage },
  });

  const pageArchives = formatPagesForList(data);
  return { pageArchives, totalPagesArchive: getTotalPagePages(data?.pages) };
}

export async function getPostByGameId(gameId) {
  const data = await fetchAPI("post-by-game-id", {
    params: { gameId },
  });
  return formatPostForMatchup(data);
}

export async function getPostBySlug(slug) {
  const data = await fetchAPI("post-by-slug", {
    params: { slug },
  });
  return formatPost(data);
}

const getPostsByCount = async ({
  category,
  counter = 5,
  after = "",
  search = "",
}) => {
  const data = await fetchAPI("post-by-count", {
    params: { category, after, counter, search },
  });
  const formattedData = formatPostsForList(data);

  return {
    data: formattedData,
    after: data?.posts?.pageInfo?.endCursor,
    counter,
  };
};

export async function getTopPosts(category = "", postCount = 50, search = "") {
  let remainingPosts = postCount;
  let allPosts = [];
  let after = "";

  try {
    while (remainingPosts > 0) {
      const batchSize = Math.min(5, remainingPosts); // Fetch posts in batches of 5 or less
      const {
        data,
        after: nextAfter,
        counter,
      } = await getPostsByCount({
        category,
        counter: batchSize,
        after,
        search,
      });

      if (data.length === 0) {
        // No more posts to fetch, exit the loop
        break;
      }

      allPosts = allPosts.concat(data);
      after = nextAfter;
      remainingPosts -= counter;
    }
  } catch (error) {
    const {
      data,
      after: nextAfter,
      counter,
    } = await getPostsByCount({
      category,
      counter: 0,
      after,
      search,
    });

    allPosts = allPosts.concat(data);
  }
  return allPosts;
}

export async function getLatestFivePostsSlugs(category) {
  const data = await fetchAPI("latest-five-posts-slugs", {
    params: { category },
  });

  return formatAllCategoryPostsSlug({ data, category });
}

export async function getTagsForArchivePage() {
  const data = await fetchAPI("tags-for-archive-page");
  return formatTagsForArchivePage(data);
}

export async function getPostArchivesByTag({ type, value, pageNumber }) {
  const POSTS_PER_REQUEST = 10;
  const offset = pageNumber * POSTS_PER_ARCHIVE_PAGE;

  const types = {
    author: "authorName",
    tag: "tag",
    search: "search",
  };

  const { archives, totalPages } = await fetchAndFormatArchivePostsByTag({
    value,
    type: types[type] || types["tag"],
    offset,
    perPage: POSTS_PER_REQUEST,
  });

  return { archives, totalPages, tag: value };
}

async function fetchAndFormatArchivePostsByTag({
  type,
  value,
  offset,
  perPage,
}) {
  const data = await fetchAPI("archive-posts", {
    params: { type, value, offset, perPage },
  });

  const archives = formatPostsByTagForList(data);

  return { archives, totalPages: getTotatPostsPages(data?.posts) };
}

export async function getPostByDatabaseId(id) {
  const data = await fetchAPI("post-by-database-id", {
    params: { id },
  });
  const formattedData = {
    uri: data?.post?.uri,
    author: data?.post?.author?.node?.slug,
    categories: data?.post?.categories?.nodes?.map((item) => item?.slug),
  };
  return formattedData;
}

export async function getPageUriByDatabaseId(id) {
  const data = await fetchAPI("page-uri-by-database-id", {
    params: { id },
  });

  return data?.page?.uri;
}

export async function getPagesToSidebar() {
  const data = await fetchAPI("pages-to-sidebar");
  return data;
}

export async function getBanners() {
  const data = await fetchAPI("banners");
  return formatBanners(data);
}

export async function getDataPagesWithTags(tags) {
  const data = await fetchAPI("pages-with-tags", {
    params: { tags },
  });
  return data;
}

export async function getPagesWithTags(tags) {
  return formatPagesWithTags(tags);
}

export async function getShows() {
  const data = await fetchAPI("shows");
  return formatShows(data);
}

export async function getMenus() {
  const data = await fetchAPI("menus");
  return formatTopMenu(data);
}
