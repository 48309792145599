import { LEAGUE_PAGES, SOCCER_LEAGUES } from "@/config/constants";
import { CONSENSUS_PICKS_MENU } from "@/config/menus";

// Determines current league based on URL / site route
export function getLeagueFromRoute(archive, category, path) {
  if (path) {
    return [...LEAGUE_PAGES, ...SOCCER_LEAGUES].includes(path)
      ? path
      : CONSENSUS_PICKS_MENU.all.id;
  } else if (category) {
    return LEAGUE_PAGES.includes(category)
      ? category
      : CONSENSUS_PICKS_MENU.all.id;
  } else if (archive && Array.isArray(archive)) {
    const lowerCaseArchive = archive.map((item) => item.toLowerCase());
    const league = LEAGUE_PAGES.find((page) =>
      lowerCaseArchive.includes(page.toLowerCase())
    );
    return CONSENSUS_PICKS_MENU[league]?.id || CONSENSUS_PICKS_MENU.all.id;
  }
  return CONSENSUS_PICKS_MENU.all.id;
}
