export function formatHorseTracks(tracks) {
  if (!tracks?.length > 0) return [];
  return tracks.reduce((trackMap, item) => {
    if (item && item.name && item.url) {
      trackMap[item.name] = item.url;
    }
    return trackMap;
  }, {});
}

// Combine horse track URLs with hardcoded horse racing URLs from menu constants
export function addHorseTracksToNav(horseTracks, topmenu) {
  const TOP_MENUS = topmenu || {};

  const navMenus = { ...TOP_MENUS }; // Deep clone
  const initialHorseRacingNavOptions =
    TOP_MENUS["Horse Racing"]?.menuOptions || {};
  const initialHorseRacingNavOptionKeys = Object.keys(
    initialHorseRacingNavOptions
  );
  const firstKey = initialHorseRacingNavOptionKeys.shift(); // Get the first key and remove it from the array

  const firstOption = {};
  if (firstKey) {
    firstOption[firstKey] = initialHorseRacingNavOptions[firstKey];
  }

  const remainingOptions = initialHorseRacingNavOptionKeys.reduce(
    (acc, key) => {
      acc[key] = initialHorseRacingNavOptions[key];
      return acc;
    },
    {}
  );

  if (navMenus && navMenus["Horse Racing"]) {
    navMenus["Horse Racing"].menuOptions = {
      ...firstOption,
      ...horseTracks,
      ...remainingOptions,
    };
    return navMenus;
  }

  return navMenus;
}
