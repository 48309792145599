// Format WP shows content / data
export function formatShows(data) {
  const edges = data?.shows?.edges || [];

  return edges.map((edge) => {
    const show = edge?.node || {};

    return show;
  });
}
