export function formatTopMenu(data) {
  const edges = data?.menuItems?.edges || [];

  const buildMenuOptions = (nodes) => {
    return nodes.reduce((acc, edge) => {
      const node = edge?.node;
      if (!node) return acc;

      const { label, path, childItems } = node;
      const childEdges = childItems?.edges || [];

      if (childEdges.length > 0) {
        if (
          label === "Soccer Picks" ||
          label === "Soccer Odds" ||
          label === "Soccer News"
        ) {
          acc[label] = {
            league: true,
            menuTitle: label,
            path: path,
            menuOptions: buildMenuOptions(childEdges),
          };
        } else {
          acc[label] = {
            parent: true,
            menuTitle: label,
            path: path,
            menuOptions: buildMenuOptions(childEdges),
          };
        }
      } else {
        acc[label] = path;
      }
      return acc;
    }, {});
  };

  return edges.reduce((acc, edge) => {
    const node = edge?.node;
    if (!node) return acc;

    const menuTitle = node.label || "";
    const childItems = node.childItems.edges || [];

    acc[menuTitle] = {
      menuTitle,
      menuOptions: buildMenuOptions(childItems),
    };

    return acc;
  }, {});
}
