import { BN_URL } from "@/config/constants";

import {
  formatOgImageWithFullHead,
  formatOpenGraphImage,
  formatSeoAuthor,
} from "./seo";
import { unescapeHTML } from "./word";

// Format WP category content / data
export function formatCategories(data) {
  const edges = data?.categories?.edges || [];
  return edges.reduce(
    (categoryMap, edge) => ({
      ...categoryMap,
      [edge?.node?.slug]: edge?.node?.uri,
    }),
    {}
  );
}

// Format WP category content / data for archive page
export function formatCategoriesForArchivePage(data) {
  const edges = data?.categories?.edges || [];
  return edges.reduce(
    (categoryMap, edge) => ({
      ...categoryMap,
      [edge?.node?.slug]: {
        ...edge?.node,
        seo: {
          title: edge?.node?.seo?.title || unescapeHTML(edge?.node?.name),
          metaDesc: edge?.node?.seo?.metaDesc,
          publishedTime: edge?.node?.seo?.opengraphPublishedTime ?? "",
          modifiedTime: edge?.node?.seo?.opengraphModifiedTime ?? "",
          canonical: `${BN_URL}${edge?.node?.uri}`,
          ogType: edge?.node?.seo?.opengraphType ?? null,
          ogImage: edge?.node?.seo?.opengraphImage
            ? formatOpenGraphImage(edge?.node?.seo?.opengraphImage)
            : formatOgImageWithFullHead(edge?.node?.seo?.fullHead) ?? null,
          schema: edge?.node?.seo?.schema?.raw ?? null,
          author: formatSeoAuthor(edge?.node?.author) ?? null,
        },
      },
    }),
    {}
  );
}

export function formatAllCategoryPostsSlug({ data, category }) {
  const edges = data?.posts?.edges || [];
  return edges.map((item) => ({
    slug: item?.node?.slug,
    category,
  }));
}

export const getCategoriesFromMenuOptions = (menuOptions) => {
  const categories = [];

  for (const category in menuOptions) {
    if (Object.prototype.hasOwnProperty.call(menuOptions, category)) {
      let uri = menuOptions[category];

      if (typeof uri === "object") {
        uri = uri?.path;
      }

      if (uri && uri.includes("/category/")) {
        const parts = uri.split("/");
        const category = parts[3];
        categories.push(category);
      }
    }
  }

  return categories;
};

export const getSearchByCategories = (category, slug) => {
  if (category === "miscellaneous-sports") {
    if (slug.includes("rugby") || slug.includes("afl") || slug.includes("nrl"))
      return "rugby";
    if (slug.includes("cricket")) return "cricket";
    if (slug.includes("cfl")) return "cfl";
  }
  return "";
};
