import { LEAGUE_GROUPINGS } from "@/config/constants";

// Format team betting record table data based on standings
function formatBettingRecord(standings) {
  const sortedStandings = standings.sort(
    (a, b) => (b?.spread?.wins || 0) - (a?.spread?.wins || 0)
  );
  const formattedStandings = sortedStandings.map((item) => {
    const { team, spread, home, away, over_under: overUnder } = item;
    return {
      team: {
        abbreviation: team?.abbr,
        logo: team?.logo,
        name: team?.name || team?.full_name,
      },
      ats: `${spread?.wins || 0}-${spread?.losses || 0}-${spread?.push || 0}`,
      atsHome: `${home?.spread?.wins || 0}-${home?.spread?.losses || 0}-${home?.spread?.push || 0}`,
      atsAway: `${away?.spread?.wins || 0}-${away?.spread?.losses || 0}-${away?.spread?.push || 0}`,
      overUnder: `${overUnder?.over || 0}-${overUnder?.under || 0}-${overUnder?.push || 0}`,
    };
  });
  return {
    "Betting Record": {
      title: "Betting Record",
      standings: formattedStandings,
    },
  };
}

// Format team data for standings tables
function formatTeam(team, league) {
  let variableFields = {};
  switch (league) {
    case "mlb":
      variableFields = {
        winPercentage: `${(team?.win_per * 100)?.toFixed(1)}%`,
        runsFor: team?.runs_for?.toFixed(1),
        runsAgainst: team?.runs_against?.toFixed(1),
        runsDifference: team?.runs_diff?.toFixed(1),
        homeRecord: `${team?.home?.wins}-${team?.home?.losses}`,
        awayRecord: `${team?.away?.wins}-${team?.away?.losses}`,
      };
      break;
    case "nhl":
      variableFields = {
        overtimeLosses: team?.otl,
        points: team?.wins * 2 + team?.otl,
        winPercentage: `${(team?.win_per * 100)?.toFixed(1)}%`,
        goalsFor: team?.goals_for,
        goalsAgainst: team?.goals_against,
        goalsDifference: team?.goals_diff,
        homeRecord: `${team?.home?.wins}-${team?.home?.losses}-${team?.home?.otl}`,
        awayRecord: `${team?.away?.wins}-${team?.away?.losses}-${team?.away?.otl}`,
      };
      break;
    default:
      variableFields = {
        winPercentage: `${(team?.win_per * 100)?.toFixed(1)}%`,
        pointsFor: (team?.points_for / team?.games)?.toFixed(1),
        pointsAgainst: (team?.points_against / team?.games)?.toFixed(1),
        pointsDifference: (team?.points_diff / team?.games)?.toFixed(1),
        homeRecord: `${team?.home?.wins}-${team?.home?.losses}`,
        awayRecord: `${team?.away?.wins}-${team?.away?.losses}`,
      };
  }

  return {
    team: {
      abbreviation: team?.team?.abbr,
      logo: team?.team?.logo,
      name: team?.team?.name || team?.team?.full_name,
    },
    gamesPlayed: team?.games,
    wins: team?.wins,
    losses: team?.losses,
    ...variableFields,
  };
}

// Calculate games back from lead
function getGamesBack(initialTeam, team) {
  let gamesBack = initialTeam?.wins - team?.wins;
  if (initialTeam?.gamesPlayed > team?.gamesPlayed) {
    gamesBack -= (initialTeam?.gamesPlayed - team?.gamesPlayed) * 0.5;
  } else {
    gamesBack += (team?.gamesPlayed - initialTeam?.gamesPlayed) * 0.5;
  }
  return gamesBack;
}

// Sort standings within groups
function sortGroups(groupedStandings, league) {
  const standings = JSON.parse(JSON.stringify(groupedStandings));

  Object.entries(standings).forEach(([key, group]) => {
    const groupStandings = group?.standings;
    const initialTeam = groupStandings?.[0];
    groupStandings.forEach((team) => {
      if (league === "nhl") {
        team.points = team?.wins * 2 + team?.overtimeLosses;
      } else {
        team.gamesBack = getGamesBack(initialTeam, team);
      }
    });
    standings[key].standings = groupStandings.sort((a, b) =>
      league === "nhl" ? b?.points - a?.points : a?.gamesBack - b?.gamesBack
    );
  });

  return standings;
}

// Format and group league standings based on grouping key
function formatAndGroupStandingsByKey(standings, key, league) {
  const groupedStandings = LEAGUE_GROUPINGS?.[league]?.[key] || {};
  standings.forEach((item) => {
    const group = item?.team?.[key];
    if (!groupedStandings[group]) {
      groupedStandings[group] = {
        title: group,
        standings: [],
      };
    }
    groupedStandings[group].standings.unshift(formatTeam(item, league));
  });
  return groupedStandings;
}

export function formatLeagueStandings(standings, league) {
  // If league has conference grouping, add to standings data
  return standings.map((standing) => {
    let conferenceGrouping = null;
    const standingsSeason = standing && Object.keys(standing)?.[0];
    const standingsValues = standing && Object.values(standing)?.[0];
    const hasConferenceGrouping =
      standingsValues && standingsValues[0]?.team?.conference;
    if (hasConferenceGrouping) {
      conferenceGrouping = formatAndGroupStandingsByKey(
        standingsValues,
        "conference",
        league
      );
    }

    // If league has league grouping, add to standings data
    let leagueGrouping = null;
    const hasLeagueGrouping =
      standingsValues && standingsValues[0]?.team?.league;
    if (hasLeagueGrouping) {
      leagueGrouping = formatAndGroupStandingsByKey(
        standingsValues,
        "league",
        league
      );
    } else if (league === "nhl") {
      leagueGrouping = {
        League: {
          title: "League",
          standings: standingsValues.map((item) => formatTeam(item, league)),
        },
      };
    }

    const divisionGrouping = formatAndGroupStandingsByKey(
      standingsValues,
      "division",
      league
    );

    return {
      bettingRecord: formatBettingRecord(standingsValues),
      division: sortGroups(divisionGrouping, league),
      ...(conferenceGrouping && {
        conference: sortGroups(conferenceGrouping, league),
      }),
      ...(leagueGrouping && { league: sortGroups(leagueGrouping, league) }),
      season: standingsSeason,
    };
  });
}
