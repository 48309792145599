import * as dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(isToday);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

export function getArticleCardDate(date) {
  return date && dayjs(date).isToday()
    ? dayjs(date).fromNow()
    : dayjs(date).format("ddd MMM D YYYY");
}

export function getGameCardAriaLabel(awayTeam, homeTeam, startTime) {
  return `${awayTeam} vs. ${homeTeam} - ${dayjs(startTime).format(
    "D MMM h:mm A"
  )}`;
}

export function getGameStartDateAndTime(startTime) {
  return {
    date: dayjs(startTime).format("MMM DD"),
    time: dayjs(startTime).format("h:mm A"),
  };
}

export function getFooterYear() {
  return dayjs().year();
}

export function getMatchUpStartTime(startTime) {
  return dayjs(startTime).local();
}

export function getOddsRowAriaLabel(awayTeam, homeTeam, startTime) {
  return `${awayTeam} vs. ${homeTeam} - ${dayjs(startTime).format(
    "D MMM h:mm A"
  )}`;
}

export function getOddsRowStartTime(startTime) {
  return dayjs(startTime).format("h:mm A");
}

export function getOddsTableDate() {
  return dayjs().format("MMMM D, YYYY");
}

export function getPostDate(date) {
  return dayjs(date).format("ddd MMM DD YYYY");
}

export function getPostDateByAuthor(date) {
  const originalDate = dayjs.utc(date);
  const transformedDateString = originalDate.format(
    "ddd MMM DD, YYYY, HH:mm A"
  );
  return transformedDateString;
}

export function getStartTimeDate({ startTime, format = "YYYY-MM-DD HH:mm" }) {
  const gameStartTime = dayjs(startTime).local();
  return gameStartTime.format(format);
}

export function getDateDifference(date) {
  const targetDate = dayjs(date).tz("America/Toronto", true);
  const currentDate = dayjs().tz("America/Toronto");
  const diff = targetDate.diff(currentDate, "minute");

  const daysDiff = Math.floor(diff / (60 * 24));
  const hoursDiff = Math.floor((diff % (60 * 24)) / 60);
  const minutesDiff = diff % 60;
  return {
    daysDiff: daysDiff || 0,
    hoursDiff: hoursDiff || 0,
    minutesDiff: minutesDiff || 0,
  };
}

export function getESTTime() {
  return dayjs().tz("America/Toronto").format("YYYY-MM-DD HH:mm");
}

export const showTime = (
  timeString,
  userTimeZone,
  format = "America/Toronto"
) => {
  const [hour, minute, second] = timeString.split(":").map(Number);

  return dayjs()
    .tz(format)
    .hour(hour)
    .minute(minute)
    .second(second)
    .tz(userTimeZone);
};
