// components/ErrorBoundary.js
import * as Sentry from "@sentry/react";

import { IS_PROD } from "@/config/constants";

const ErrorBoundary = ({ children }) => {
  if (IS_PROD) {
    return <>{children}</>;
  }

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <div className="mx-5 my-5 flex h-full flex-col gap-4">
          <div>You have encountered an error</div>
          <div>{error.toString()}</div>
          <div>{componentStack}</div>
          <div className="flex w-full items-center justify-center text-center">
            <button
              className="w-max rounded bg-card-link-blue px-4 py-2 font-bold text-white"
              onClick={() => {
                setSentryMessage({ message: "This is my app" });
                {
                  /* When resetError() is called it will remove the Fallback component */
                }
                {
                  /* and render the Sentry ErrorBoundary's children in their initial state */
                }
                resetError();
              }}
            >
              Click here to reset!
            </button>
          </div>
        </div>
      )}
      onError={(error, componentStack) =>
        Sentry.captureException(error, { data: componentStack })
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
