import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import {
  getCollegeGames,
  getGames,
  getSoccerGames,
  getWNBAGames,
} from "@/pages/api/apiBettingsNews";

const initialState = {
  games: { data: null, isLoading: true },
  collegeGames: { data: null, isLoading: true },
  soccerGames: { data: null, isLoading: true },
  wnbaGames: { data: null, isLoading: true },
  selectedGames: null,
};

function gamesReducer(state, action) {
  switch (action.type) {
    case "FETCH_START":
      return {
        ...state,
        [action.payload.gameType]: {
          ...state[action.payload.gameType],
          isLoading: true,
        },
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        [action.payload.gameType]: {
          data: action.payload.games,
          isLoading: false,
        },
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        [action.payload.gameType]: { data: [], isLoading: false },
      };
    case "SET_SELECTED_GAMES":
      return { ...state, selectedGames: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}
export const GamesContext = createContext();

export const GamesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(gamesReducer, initialState);

  const fetchGames = useCallback(async (fetchFunction, gameType) => {
    dispatch({ type: "FETCH_START", payload: { gameType } });
    try {
      const games = await fetchFunction();
      dispatch({ type: "FETCH_SUCCESS", payload: { gameType, games } });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch games:", error);
      dispatch({ type: "FETCH_FAILURE", payload: { gameType } });
    }
  }, []);

  useEffect(() => {
    fetchGames(getGames, "games");
    fetchGames(getSoccerGames, "soccerGames");
    fetchGames(getCollegeGames, "collegeGames");
    fetchGames(getWNBAGames, "wnbaGames");
  }, [fetchGames]);

  const value = useMemo(
    () => ({
      games: state.games.data,
      collegeGames: state.collegeGames.data,
      soccerGames: state.soccerGames.data,
      wnbaGames: state.wnbaGames.data,
      isGamesLoading: state.games.isLoading,
      isSoccerGamesLoading: state.soccerGames.isLoading,
      isCollegeGamesLoading: state.collegeGames.isLoading,
      isWnbaGamesLoading: state.wnbaGames.isLoading,
      selectedGames: state.selectedGames,
      setSelectedGames: (games) =>
        dispatch({ type: "SET_SELECTED_GAMES", payload: games }),
    }),
    [
      state.games,
      state.collegeGames,
      state.soccerGames,
      state.wnbaGames,
      state.selectedGames,
    ]
  );

  return (
    <GamesContext.Provider value={value}>{children}</GamesContext.Provider>
  );
};
