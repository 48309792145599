export async function fetchAPICommon(url, options, type, extraData) {
  let delay = 10;
  const retries = 5;
  for (let i = 0; i < retries; i++) {
    try {
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.errors) {
          throw new Error("Failed to fetch API");
        }
        if (type === "standings") return { [extraData?.season]: json.data };
        return json.data;
      }
      return {};
    } catch (error) {
      if (i < retries - 1) {
        await new Promise((resolve) => setTimeout(resolve, delay));
        delay *= 2;
      } else {
        // eslint-disable-next-line no-console
        console.error(
          `All ${retries} attempts ${url} failed. Last error: ${error.message}`
        );
        return {};
      }
    }
  }
}
