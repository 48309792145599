import { RESTRICTED_AFFILIATES_STATES } from "@/config/constants";
import { getUserLocation } from "@/pages/api/apiAbstract";

// Format WP affiliate content / data
export async function formatAffiliates(data) {
  let userLocation;
  if (typeof window !== "undefined") {
    userLocation = await getUserLocation();
  }

  const edges = data?.affiliates?.edges || [];

  const filteredEdges = edges.filter((edge) => {
    const title = edge?.node?.title || "";
    const restrictions = RESTRICTED_AFFILIATES_STATES[title] || [];

    if (!userLocation || restrictions.length === 0) {
      return true;
    }
    return !restrictions.includes(userLocation);
  });

  return filteredEdges.map((edge) => {
    const item = edge?.node;
    if (!item) return {};

    const { id, title } = item;
    const affiliateInfo =
      {
        ...item.affiliateInfo,
      } || {};

    return {
      id,
      title,
      ...affiliateInfo,
    };
  });
}
