import { getCookie, setCookie } from "@/utilities/cookies";

const API_KEY = process.env.NEXT_PUBLIC_ABSTRACT_API_KEY;
const API_URL = `https://ipgeolocation.abstractapi.com/v1/?api_key=${API_KEY}`;

export async function getUserLocation() {
  if (typeof window === "undefined") {
    return null;
  }

  const storedLocation = getCookie("userLocation");

  if (storedLocation) {
    return JSON.parse(storedLocation);
  }

  try {
    const response = await fetch(API_URL);
    const data = await response.json();
    if (response.ok) {
      const location = data.region;
      setCookie("userLocation", JSON.stringify(location), 31);
      return location;
    } else {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch location:", data.error);
      return null;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching user location:", error);
    return null;
  }
}
