import { affiliateLinks, SITE_LOGOS_SMALL } from "@/config/constants";

/**
 *
 * @param {object} site
 * @param {function} getPickLocation
 * @returns true or false
 */
function getIsValidPickSite(site, getPickLocation) {
  const hasAwayScore = site?.predictions?.away?.score > 0;
  const hasHomeScore = site?.predictions?.home?.score;
  const { pick = null } = getPickLocation(site) || {};
  return hasAwayScore && hasHomeScore && pick;
}

/**
 *
 * @param {array} sites - betting site predictions
 * @param {function} getIsAPickMatch - returns true if a match for aPick, false if not
 * @returns an object with a (home) and b (away) picks and percentages
 */
export function getPicksAndPercentages(sites, getIsAPickMatch) {
  let numSites = 0;
  let aPick = 0;
  let bPick = 0;

  sites?.forEach((site) => {
    numSites++;
    if (getIsAPickMatch(site)) {
      aPick++;
    } else {
      bPick++;
    }
  });

  return {
    aPick,
    aPer: (aPick / numSites) * 100,
    bPick,
    bPer: (bPick / numSites) * 100,
  };
}

// Determines home / away pick percentages, pick, and site count used
export function getPercentages(sites, getPickLocation, pickComparison) {
  sites = sites?.filter((site) => getIsValidPickSite(site, getPickLocation));
  const { aPer, bPer } = getPicksAndPercentages(
    sites,
    (site) => getPickLocation(site)?.pick === pickComparison
  );
  return {
    home: Number(aPer)?.toFixed(0) > 0 ? `${Number(aPer)?.toFixed(0)}%` : "-",
    away: Number(bPer)?.toFixed(0) > 0 ? `${Number(bPer)?.toFixed(0)}%` : "-",
    pick: aPer !== bPer ? (aPer > bPer ? "home" : "away") : "tie",
    siteCount: sites.length,
  };
}

// Format game card data for consensus picks game cards
function getGameCardData(game) {
  const oddsConsensus = game?.odds?.consensus;
  const sites = game?.predictions?.sites;
  return (
    game?.odds && {
      moneyLine: getPercentages(
        [...sites, ...game?.predictions?.ml_only_sites],
        (site) => site?.picks?.money_line,
        "home"
      ),
      moneyLineAwayOdds: oddsConsensus?.money_line?.away?.odds,
      moneyLineHomeOdds: oddsConsensus?.money_line?.home?.odds,

      pointSpread: getPercentages(sites, (site) => site?.picks?.spread, "home"),
      pointSpreadAwaySpread: oddsConsensus?.spread?.away?.spread,
      pointSpreadHomeSpread: oddsConsensus?.spread?.home?.spread,

      overUnder: getPercentages(
        sites,
        (site) => site?.picks?.over_under,
        "under"
      ),
      overUnderOverPoints: oddsConsensus?.over_under?.over?.points,
      overUnderUnderPoints: oddsConsensus?.over_under?.under?.points,

      siteCount: game?.predictions?.site_count,
    }
  );
}

// Format money line for picks pages
function getMoneylinePicks(game) {
  if (
    !game?.best_bets ||
    !game?.predictions?.sites ||
    game?.predictions?.sites.length === 0
  ) {
    return null;
  }
  const sites =
    Object.entries(game?.odds?.sites ?? {})?.map(([name, site]) => ({
      name,
      ...site,
    })) ?? [];

  const away = sites.find((site) => site?.money_line?.away?.best);
  const home = sites.find((site) => site?.money_line?.home?.best);
  const draw = sites.find((site) => site?.money_line?.draw?.best);
  const percentages = getPercentages(
    [...game?.predictions?.sites, ...game?.predictions?.ml_only_sites],
    (site) => site?.picks?.money_line,
    "home"
  );

  return {
    siteCount: percentages?.siteCount,
    away: {
      bestLink: away?.link,
      bestLogo: SITE_LOGOS_SMALL[away?.name],
      bestPrimary: away?.money_line?.away?.odds || "n/a",
      bestSite: away?.name,
      consensus: percentages?.away || "n/a",
      consensusPick: percentages?.pick === "away",
      publicBet: game?.public?.money_line?.away
        ? `${Number(game?.public?.money_line?.away)?.toFixed(0)}%`
        : "n/a",
      publicBetPick:
        game?.public?.money_line?.away > game?.public?.money_line?.home,
    },
    home: {
      bestLink: home?.link,
      bestLogo: SITE_LOGOS_SMALL[home?.name],
      bestPrimary: home?.money_line?.home?.odds || "n/a",
      bestSite: home?.name,
      consensus: percentages?.home || "n/a",
      consensusPick: percentages?.pick === "home",
      publicBet: game?.public?.money_line?.home
        ? `${Number(game?.public?.money_line?.home)?.toFixed(0)}%`
        : "n/a",
      publicBetPick:
        game?.public?.money_line?.home > game?.public?.money_line?.away,
    },
    draw: {
      bestLink: draw?.link,
      bestLogo: SITE_LOGOS_SMALL[draw?.name],
      bestPrimary: draw?.money_line?.draw?.odds || "n/a",
      bestSite: draw?.name,
      consensus: percentages?.draw || "n/a",
      consensusPick: percentages?.pick === "draw",
      publicBet: game?.public?.money_line?.draw
        ? `${Number(game?.public?.money_line?.draw)?.toFixed(0)}%`
        : "n/a",
      publicBetPick:
        game?.public?.money_line?.home > game?.public?.money_line?.away,
    },
  };
}

// Format money line for odds pages
function getMoneylineOdds(game) {
  const sites = game?.odds?.sites;
  return (
    game?.odds &&
    Object.entries(sites)?.reduce(
      (oddsSiteMap, [siteKey, site]) => ({
        ...oddsSiteMap,
        [siteKey]: {
          awayPrimary: site?.money_line?.away?.odds,
          isAwayBest: site?.money_line?.away?.best,
          homePrimary: site?.money_line?.home?.odds,
          isHomeBest: site?.money_line?.home?.best,
          draw: site?.money_line?.draw?.odds || 0,
          isDrawBest: site?.money_line?.draw?.best || false,
        },
      }),
      {}
    )
  );
}

// Format point spreads for picks pages
function getPointSpreadPicks(game) {
  if (
    !game?.best_bets ||
    !game?.predictions?.sites ||
    game?.predictions?.sites.length === 0
  ) {
    return null;
  }
  const sites =
    Object.entries(game?.odds?.sites ?? {})?.map(([name, site]) => ({
      name,
      ...site,
    })) ?? [];

  const away = sites.find((site) => site?.spread?.away?.best);
  const home = sites.find((site) => site?.spread?.home?.best);
  const percentages = getPercentages(
    game?.predictions?.sites,
    (site) => site?.picks?.spread,
    "home"
  );

  return {
    siteCount: percentages?.siteCount,
    away: {
      bestLink: away?.link,
      bestLogo: SITE_LOGOS_SMALL[away?.name],
      bestPrimary: away?.spread?.away?.spread || "n/a",
      bestSecondary: away?.spread?.away?.odds || "n/a",
      bestSite: away?.name,
      consensus: percentages?.away || "n/a",
      consensusPick: percentages?.pick === "away",
      publicBet: game?.public?.spread?.away
        ? `${Number(game?.public?.spread?.away)?.toFixed(0)}%`
        : "n/a",
      publicBetPick: game?.public?.spread?.away > game?.public?.spread?.home,
    },
    home: {
      bestLink: home?.link,
      bestLogo: SITE_LOGOS_SMALL[home?.name],
      bestPrimary: home?.spread?.home?.spread || "n/a",
      bestSecondary: home?.spread?.home?.odds || "n/a",
      bestSite: home?.name,
      consensus: percentages?.home || "n/a",
      consensusPick: percentages?.pick === "home",
      publicBet: game?.public?.spread?.home
        ? `${Number(game?.public?.spread?.home)?.toFixed(0)}%`
        : "n/a",
      publicBetPick: game?.public?.spread?.home > game?.public?.spread?.away,
    },
  };
}

// Format point spread for odds pages
function getPointSpreadOdds(game) {
  const sites = game?.odds?.sites;
  return (
    game?.odds &&
    Object.entries(sites)?.reduce(
      (oddsSiteMap, [siteKey, site]) => ({
        ...oddsSiteMap,
        [siteKey]: {
          awayPrimary: site?.spread?.away?.spread,
          awaySecondary: site?.spread?.away?.odds,
          isAwayBest: site?.spread?.away?.best,
          homePrimary: site?.spread?.home?.spread,
          homeSecondary: site?.spread?.home?.odds,
          isHomeBest: site?.spread?.home?.best,
        },
      }),
      {}
    )
  );
}

// Format total picks for picks pages
function getTotalPicks(game) {
  if (
    !game?.best_bets ||
    !game?.predictions?.sites ||
    game?.predictions?.sites.length === 0
  ) {
    return null;
  }

  const sites = Object.entries(game?.odds?.sites ?? {})?.map(
    ([name, site]) => ({
      name,
      ...site,
    })
  );
  const away = sites.find((site) => site?.over_under?.over?.best);
  const home = sites.find((site) => site?.over_under?.under?.best);
  const percentages = getPercentages(
    game?.predictions?.sites,
    (site) => site?.picks?.over_under,
    "over"
  );

  return {
    siteCount: percentages?.siteCount,
    away: {
      bestLink: away?.link,
      bestLogo: SITE_LOGOS_SMALL[away?.name],
      bestPrimary: away?.over_under?.over?.odds
        ? `o${away?.over_under?.over?.points}`
        : "n/a",
      bestSecondary: away?.over_under?.over?.odds || "n/a",
      bestSite: away?.name,
      consensus: percentages?.away || "n/a",
      consensusPick: percentages?.pick === "away",
      publicBet: game?.public?.over_under?.away
        ? `${Number(game?.public?.over_under?.away)?.toFixed(0)}%`
        : "n/a",
      publicBetPick:
        game?.public?.over_under?.away > game?.public?.over_under?.home,
    },
    home: {
      bestLink: home?.link,
      bestLogo: SITE_LOGOS_SMALL[home?.name],
      bestPrimary: home?.over_under?.under?.odds
        ? `u${home?.over_under?.under?.points}`
        : "n/a",
      bestSecondary: home?.over_under?.under?.odds || "n/a",
      bestSite: home?.name,
      consensus: percentages?.home || "n/a",
      consensusPick: percentages?.pick === "home",
      publicBet: game?.public?.over_under?.home
        ? `${Number(game?.public?.over_under?.home)?.toFixed(0)}%`
        : "n/a",
      publicBetPick:
        game?.public?.over_under?.home > game?.public?.over_under?.away,
    },
  };
}

// Format total odds for odds pages
function getTotalOdds(game) {
  const sites = game?.odds?.sites;
  return (
    game?.odds &&
    Object.entries(sites)?.reduce(
      (oddsSiteMap, [siteKey, site]) => ({
        ...oddsSiteMap,
        [siteKey]: {
          awayPrimary: `o${site?.over_under?.over?.points}`,
          awaySecondary: site?.over_under?.over?.odds,
          isAwayBest: site?.over_under?.over?.best,
          homePrimary: `o${site?.over_under?.under?.points}`,
          homeSecondary: site?.over_under?.under?.odds,
          isHomeBest: site?.over_under?.under?.best,
          draw: site?.money_line?.draw?.odds || 0,
          isDrawBest: site?.money_line?.draw?.best || false,
        },
      }),
      {}
    )
  );
}

export function formatGame(game, league) {
  const gameKey = game?.game_key?.toLowerCase();
  const formattedLeague = league || game?.league;
  const isGamePostponed =
    game?.game_status?.includes("PPD") ||
    game?.game_status?.includes("Postponed");
  return {
    awayTeam: {
      abbreviation: game?.away_team?.abbr,
      logo: game?.away_team?.logo,
      name: game?.away_team?.full_name,
    },
    homeTeam: {
      abbreviation: game?.home_team?.abbr,
      logo: game?.home_team?.logo,
      name: game?.home_team?.full_name,
    },
    isGameCardLinkDisabled: isGamePostponed || !game?.odds,
    // Consensus Picks game card data
    gameCard: getGameCardData(game),
    gameKey,
    league: formattedLeague,
    matchupLink: `/${formattedLeague}/matchup/${gameKey}`,
    // Odds Page data
    odds: {
      moneyLine: getMoneylineOdds(game),
      pointSpread: getPointSpreadOdds(game),
      total: getTotalOdds(game),
    },
    // Picks page data
    picks: {
      moneyLine: getMoneylinePicks(game),
      pointSpread: getPointSpreadPicks(game),
      total: getTotalPicks(game),
    },
    sites: Object.entries(game?.odds?.sites || {}).reduce(
      (siteMap, [siteName, site]) => ({
        ...siteMap,
        [siteName]: site?.link,
      }),
      {}
    ),
    venue: {
      location: game?.venue?.venue_location,
      name: game?.venue?.venue_name,
    },
    startTime: game?.start_time,
    gameStatus: game?.game_status,
  };
}

export function formatWnbaGame(game) {
  const gameKey = game?.game_key?.toLowerCase()?.replace(" 00:00:00", "");
  const league = "wnba";
  const isGamePostponed =
    game?.game_status?.includes("PPD") ||
    game?.game_status?.includes("Postponed");
  return {
    awayTeam: {
      abbreviation: game?.away_team?.abbr,
      logo: game?.away_team?.logo,
      name: game?.away_team?.full_name,
    },
    homeTeam: {
      abbreviation: game?.home_team?.abbr,
      logo: game?.home_team?.logo,
      name: game?.home_team?.full_name,
    },
    isGameCardLinkDisabled: isGamePostponed || !game?.odds,
    // Consensus Picks game card data
    gameCard: getGameCardData(game),
    gameKey,
    league,
    matchupLink: `/${league}/matchup/${gameKey}`,
    // Odds Page data
    odds: {
      moneyLine: getMoneylineOdds(game),
      pointSpread: getPointSpreadOdds(game),
      total: getTotalOdds(game),
    },
    // Picks page data
    picks: {
      moneyLine: getMoneylinePicks(game),
      pointSpread: getPointSpreadPicks(game),
      total: getTotalPicks(game),
    },
    sites: Object.entries(game?.odds?.sites || {}).reduce(
      (siteMap, [siteName, site]) => ({
        ...siteMap,
        [siteName]: site?.link,
      }),
      {}
    ),
    venue: {
      location: game?.venue?.venue_location,
      name: game?.venue?.venue_name,
    },
    startTime: game?.start_time,
    gameStatus: game?.game_status,
  };
}

export function formatCollegeGame(game) {
  const gameKey = game?.game_key?.toLowerCase();
  const isGamePostponed =
    game?.game_status?.includes("PPD") ||
    game?.game_status?.includes("Postponed");
  const conferences = [
    game?.conference?.away_conference,
    game?.conference?.home_conference,
  ].filter(Boolean);
  return {
    awayTeam: {
      abbreviation: game?.away_team?.abbr,
      logo: game?.away_team?.logo,
      name: game?.away_team?.full_name,
    },
    homeTeam: {
      abbreviation: game?.home_team?.abbr,
      logo: game?.home_team?.logo,
      name: game?.home_team?.full_name,
    },
    isGameCardLinkDisabled: isGamePostponed || !game?.odds,
    // Consensus Picks game card data
    gameCard: getGameCardData(game),
    division: game?.division,
    gameKey,
    league: game?.league,
    conferences,
    matchupLink: `/${game?.league}/matchup/${gameKey}`,
    // Odds Page data
    odds: {
      moneyLine: getMoneylineOdds(game),
      pointSpread: getPointSpreadOdds(game),
      total: getTotalOdds(game),
    },
    // Picks page data
    picks: {
      moneyLine: getMoneylinePicks(game),
      pointSpread: getPointSpreadPicks(game),
      total: getTotalPicks(game),
    },
    sites: Object.entries(game?.odds?.sites || {}).reduce(
      (siteMap, [siteName, site]) => ({
        ...siteMap,
        [siteName]: site?.link,
      }),
      {}
    ),
    venue: {
      location: game?.venue?.venue_location,
      name: game?.venue?.venue_name,
    },
    startTime: game?.start_time,
    gameStatus: game?.game_status,
  };
}

// Format games for consensus picks, picks pages, and odds pages
export function formatGames(gameResponses) {
  const results = {};

  const games = gameResponses.filter((game) => game?.game_status !== "F");

  games.forEach((game) => {
    if (game?.odds) {
      const id = game?.game_key?.toLowerCase();
      const league = game?.league;
      const formattedGame = formatGame(game);
      results["all"] = {
        ...(results["all"] || {}),
        [id]: formattedGame,
      };
      results[league] = {
        ...(results[league] || {}),
        [id]: formattedGame,
      };
    }
  });

  return results;
}
export function formatWBNAGames(gameResponses) {
  const results = {};

  const games = gameResponses.filter((game) => game?.game_status !== "F");
  games.forEach((game) => {
    if (game?.odds) {
      const id = game?.game_key?.toLowerCase();
      const formattedGame = formatWnbaGame(game);
      results[id] = formattedGame;
    }
  });

  return results;
}

export function formatLeagueGames(gameResponses) {
  const results = {};
  const games = gameResponses.filter((game) => game?.game_status !== "F");

  games.forEach((game) => {
    if (game?.odds) {
      const id = game?.game_key?.toLowerCase();
      const league = game?.league;
      const formattedGame = formatGame(game);
      Object.assign(results, {
        ...(results[league] || {}),
        [id]: formattedGame,
      });
    }
  });
  return results;
}

export function formatCollegeGames(gameResponses) {
  const results = {};
  const games = gameResponses.filter((game) => game?.game_status !== "F");

  games.forEach((game) => {
    const id = game?.game_key?.toLowerCase();
    const league = game?.league;
    if (game?.odds) {
      const formattedGame = formatCollegeGame(game);
      results[league] = {
        ...(results[league] || {}),
        [id]: formattedGame,
      };
    }
  });
  return results;
}

export const formatPickGames = (gameRows) => {
  const marketKeys = ["moneyLine", "pointSpread", "total"];

  function transformArrayToObject(array) {
    return array.reduce((result, item, index) => {
      const title = marketKeys[index];
      const { siteCount, away, home } = item;

      result[title] = { siteCount, away, home };

      return result;
    }, {});
  }

  return gameRows?.map((game) => {
    const formattedPicksArr = Object.values(game.picks)?.map((pick, index) => {
      if (pick?.away?.bestPrimary === "0" || pick?.home?.bestPrimary === "0") {
        const validOddsIndex = Object.values(
          game.odds[marketKeys[index]]
        )?.findIndex(
          (odd) => odd?.awayPrimary !== "0" || odd?.homePrimary !== "0"
        );
        const validOdds = Object.values(game.odds[marketKeys[index]])?.find(
          (odd) => odd?.awayPrimary !== "0" || odd?.homePrimary !== "0"
        );
        const validOddsKey = Object.keys(game?.odds[marketKeys[index]])[
          validOddsIndex
        ];

        const newHomeValue = {
          ...pick.home,
          bestSite: validOddsKey,
          bestPrimary: validOdds?.homePrimary,
          bestSecondary: validOdds?.homeSecondary,
          bestLink: affiliateLinks[validOddsKey],
          bestLogo: SITE_LOGOS_SMALL[validOddsKey],
        };
        const newAwayValue = {
          ...pick.away,
          bestSite: validOddsKey,
          bestPrimary: validOdds?.awayPrimary,
          bestSecondary: validOdds?.awaySecondary,
          bestLink: affiliateLinks[validOddsKey],
          bestLogo: SITE_LOGOS_SMALL[validOddsKey],
        };
        return {
          ...pick,
          home: { ...newHomeValue },
          away: { ...newAwayValue },
        };
      } else {
        return pick;
      }
    });
    const formattedPicks = transformArrayToObject(formattedPicksArr);
    return {
      ...game,
      picks: formattedPicks,
    };
  });
};

export const formatQuickcapData = (matchup) => {
  const matchupQuickcapKeys = ["moneyLine", "overUnder", "pointSpread"];
  const formattedQuickcapArr = Object.values(matchup?.quickcap)?.map(
    (item, index) => {
      if (item.primary === "0") {
        const validOdds = matchup.odds[matchupQuickcapKeys[index]]?.find(
          (odd) => odd.away.primary !== "0" || odd.home.primary !== "0"
        );
        const newValue = validOdds?.away?.isBest
          ? {
              site: validOdds?.site,
              siteLink: validOdds?.link,
              primary: validOdds?.away?.primary,
              secondary: validOdds?.away?.secondary,
            }
          : {
              site: validOdds?.site,
              siteLink: validOdds?.link,
              primary: validOdds?.home?.primary,
              secondary: validOdds?.home?.secondary,
            };
        return {
          ...item,
          ...newValue,
        };
      }
      return item;
    }
  );
  return formattedQuickcapArr.reduce((obj, item) => {
    let key;
    switch (item.title) {
      case "Moneyline":
        key = "moneyLine";
        break;

      case "Total":
        key = "overUnder";
        break;

      case "Point Spread":
        key = "spread";
        break;

      default:
        key = "moneyLine";
        break;
    }
    obj[key] = item;
    return obj;
  }, {});
};

export const getBestValues = (odds) => {
  const extractBest = (array) => {
    const best = { home: "-", away: "-" };
    array.forEach((item) => {
      if (item.home?.isBest) best.home = item.home;
      if (item.away?.isBest) best.away = item.away;
    });
    return best || { home: "-", away: "-" };
  };
  return {
    moneyLine: extractBest(odds.moneyLine),
    pointSpread: extractBest(odds.pointSpread),
    total: extractBest(odds.total),
  };
};

export const matchupOverUnder = (bestValues, quickcapTotal) => {
  const bestValuesTotal =
    bestValues.total.away?.primary && bestValues.total.away?.secondary;

  let awayOverUnder = null;
  if (quickcapTotal === bestValuesTotal) {
    awayOverUnder = "o";
  } else {
    awayOverUnder = "u";
  }

  let homeOverUnder = null;
  if (awayOverUnder === "o") {
    homeOverUnder = "u";
  } else {
    homeOverUnder = "o";
  }

  return { awayOverUnder, homeOverUnder };
};
