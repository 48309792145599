import numeral from "numeral";

import { colorSimilarity } from "./tools";

function getDefense(team) {
  const totalGames = team?.games;
  const totalPointsAgainst = team?.points?.aga;
  return (Number(totalPointsAgainst) / Number(totalGames)).toFixed(2);
}

function getOffense(team) {
  const totalGames = team?.games;
  const totalPointsFor = team?.points?.for;
  return (Number(totalPointsFor) / Number(totalGames)).toFixed(2);
}

function getOverallDefense(team) {
  const totalGames = team?.standings?.games;
  const totalPointsAgainst = team?.standings?.points?.aga;
  return (Number(totalPointsAgainst) / Number(totalGames)).toFixed(2);
}

function getOverallOffense(team) {
  const totalGames = team?.standings?.games;
  const totalPointsFor = team?.standings?.points?.for;
  return (Number(totalPointsFor) / Number(totalGames)).toFixed(2);
}

function getPercentage(away, home) {
  return Math.round(
    (parseFloat(away) / (parseFloat(away) + parseFloat(home))) * 100
  );
}

// Format team stats data for matchup pages
export function getGameStatsSummary(game) {
  const awayTeam = game?.away_team;
  const homeTeam = game?.home_team;
  const awayTeamStandings = awayTeam?.standings;
  const homeTeamStandings = homeTeam?.standings;

  const awayOverallOffense = getOverallOffense(awayTeam);
  const homeOverallOffense = getOverallOffense(homeTeam);

  const awayOverallDefense = getOverallDefense(awayTeam);
  const homeOverallDefense = getOverallDefense(homeTeam);

  const awayOffense = getOffense(awayTeamStandings?.away);
  const homeOffense = getOffense(homeTeamStandings?.home);

  const awayDefense = getDefense(awayTeamStandings?.away);
  const homeDefense = getDefense(homeTeamStandings?.home);

  const [homeColor, awayColor] = colorSimilarity(homeTeam, awayTeam);

  return {
    offense: {
      title: "Offense",
      awayPercentage: getPercentage(awayOverallOffense, homeOverallOffense),
      away: {
        color: awayColor,
        record: awayOverallOffense,
        position: numeral(awayTeamStandings?.points?.["for-pos"]).format("o"),
      },
      home: {
        color: homeColor,
        record: homeOverallOffense,
        position: numeral(homeTeamStandings?.points?.["for-pos"]).format("o"),
      },
    },
    defense: {
      title: "Defense",
      awayPercentage: getPercentage(awayOverallDefense, homeOverallDefense),
      away: {
        color: awayColor,
        record: awayOverallDefense,
        position: numeral(awayTeamStandings?.points?.["aga-pos"]).format("o"),
      },
      home: {
        color: homeColor,
        record: homeOverallDefense,
        position: numeral(awayTeamStandings?.points?.["aga-pos"]).format("o"),
      },
    },
    awayHomeOffense: {
      title: "Away/Home Offense",
      awayPercentage: getPercentage(awayOffense, homeOffense),
      away: {
        color: awayColor,
        record: awayOffense,
        position: numeral(awayTeamStandings?.away?.points?.["for-pos"]).format(
          "o"
        ),
      },
      home: {
        color: homeColor,
        record: homeOffense,
        position: numeral(homeTeamStandings?.home?.points?.["for-pos"]).format(
          "o"
        ),
      },
    },
    awayHomeDefense: {
      title: "Away/Home Defense",
      awayPercentage: getPercentage(awayDefense, homeDefense),
      away: {
        color: awayColor,
        record: awayDefense,
        position: numeral(awayTeamStandings?.away?.points?.["aga-pos"]).format(
          "o"
        ),
      },
      home: {
        color: homeColor,
        record: homeDefense,
        position: numeral(homeTeamStandings?.home?.points?.["aga-pos"]).format(
          "o"
        ),
      },
    },
  };
}
