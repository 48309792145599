import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { useAppContext } from "@/hooks/useAppContext";
import { showTime } from "@/utilities/dates";

dayjs.extend(timezone);

const Ticker = () => {
  const router = useRouter();
  const { shows } = useAppContext();
  const [showSchedule, setShowSchedule] = useState(null);

  useEffect(() => {
    const findLiveShow = () => {
      const userTimeZone = dayjs.tz.guess();
      const now = dayjs().tz(userTimeZone);

      const liveShow = shows?.find(({ showInfo }) => {
        if (!showInfo || !Array.isArray(showInfo.day)) return false;

        const todayIsInSchedule = showInfo.day.includes(now.day().toString());

        if (!todayIsInSchedule) return false;

        const showStartTime = showTime(showInfo.startTime, userTimeZone);
        const showEndTime = showTime(showInfo.endTime, userTimeZone);

        return now?.isAfter(showStartTime) && now?.isBefore(showEndTime);
      });

      setShowSchedule(liveShow?.showInfo ?? null);
    };

    findLiveShow();

    const interval = setInterval(findLiveShow, 60000);

    return () => clearInterval(interval);
  }, [shows]);

  if (!showSchedule) return null;

  const isHomepage = router.pathname === "/";
  const isShowActive = showSchedule?.active;

  return (
    <>
      {isShowActive && (
        <div className="mb-4 rounded-md bg-gradient-to-r from-[#8c52ff] from-25% to-[#ff914d] to-75% lg:rounded-lg">
          <Link
            href={showSchedule?.bannerLink ?? ""}
            target="_blank"
            className="flex items-center justify-center"
          >
            <div
              className={`whitespace-wrap text-right text-sm font-extrabold leading-[16px] text-white lg:w-auto ${isHomepage ? "lg:text-[32px]" : "lg:text-[28px]"}`}
            >
              <span>{showSchedule?.showName ?? ""}</span>
            </div>
            <Image
              src={showSchedule?.banner ?? ""}
              alt="Live Stream Banner"
              width={275}
              height={30}
              className={`w-[34%] lg:h-[63px] lg:w-auto`}
            />
          </Link>
        </div>
      )}
    </>
  );
};

export default Ticker;
